/* Container View */
.ContainerViewContact {
  /* padding-left: 5vw; */
  margin-bottom: 5vh;
  display: flex;
  flex-direction: column;
}
.ContactStyle {
  color: #fff;
  font-family: "Dancing Script";
  font-size: 60px;
}
.VerticalViewContact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  margin-top: 5vh;
}
.ContactCard {
  width: 70vw;
  padding: 17px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  box-shadow: 0 0 5px rgb(255, 255, 255, 0.75);
  border-radius: 15px;
  backdrop-filter: blur(20px);
  background: linear-gradient(
    to bottom,
    rgba(49, 58, 91, 0),
    rgba(49, 58, 91, 0.44),
    rgba(49, 58, 91, 1)
  );
}
.BView1 {
  width: 115px;
  height: 105px;
  position: absolute;
  top: -45px;
  left: -50px;
}
.BView2 {
  width: 151px;
  height: 137.32px;
  position: absolute;
  bottom: -55px;
  right: -50px;
}
.BView3 {
  width: 141px;
  height: 122.4px;
  position: absolute;
  top: -45px;
  right: -62px;
}
.BView4 {
  width: 148px;
  height: 115.05px;
  position: absolute;
  bottom: -45px;
  left: -63px;
}
/* Info Dev */
.InfoDevContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
}
/* Right Char */
.LeftyCharStyle {
  height: 377.46px;
  /* height: 40vh; */
  width: 250px;
  /* width: 15vw; */
}
/* Left Char*/
.RightyCharStyle {
  height: 373.09px;
  width: 250px;
}
/* input Section */
.InputDevContainer {
  display: flex;
  flex-direction: column;
  /* width: 40vw; */
}
.TextStyle {
  color: #fff;
  padding-left: 25px;
  margin-bottom: 15px;
}
.TextErrorStyle {
  color: #ff4e00;
  padding-left: 25px;
}
.TextsentStyle {
  color: #ffffff;
  padding-left: 25px;
}
.custom-input {
  padding: 17px;
  box-shadow: 0 0 5px rgb(138, 137, 137);
  border-radius: 45px;
  background: rgba(0, 0, 0, 0.75);
  margin-bottom: 15px;
  color: white;
}
.custom-input::placeholder {
  color: rgb(138, 138, 138); /* Placeholder text color */
}
.custom-MessageInput {
  padding: 17px;
  vertical-align: top; /* Align text to the top */
  box-shadow: 0 0 5px rgb(138, 137, 137);
  border-radius: 45px;
  background: rgba(0, 0, 0, 0.75);
  margin-bottom: 15px;
  color: white;
  vertical-align: top;
  resize: none;
}
/* SendButton.css */
.buttonContainer {
  display: flex;
  justify-content: right;
}
.send-button {
  width: 128px;
  height: 43px;
  box-shadow: 0 0 5px rgb(138, 137, 137);
  background: rgba(0, 0, 0, 0.75);
  border-radius: 45px;
  border: 1px solid white;
  color: white;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}
.send-button:hover {
  background-color: white;
  color: black;
}
.ScoialContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  padding-left: 15px;
}
.ScoialIcon {
  height: 60px;
  margin-right: 30px;
  box-shadow: 0 0 10px 1px rgba(0, 195, 255, 0.816), 0 0 10px white; /* Adding white shadow on hover */
  border-radius: 50%;
}
 
@media screen and (max-width: 1255px) {
  .LeftyCharStyle,
  .RightyCharStyle {
    width: 15vw;
    height: 40vh;
  }
  .ScoialContainer {
    justify-content: center;
  }
  .ScoialIcon {
    height: 40px;
  }
  .BView1 {
    width: 100px;
    height: 90px;
    position: absolute;
    top: -45px;
    left: -50px;
  }
  .BView2 {
    width: 120px;
    height: 117.32px;
    position: absolute;
    bottom: -55px;
    right: -50px;
  }
  .BView3 {
    width: 121px;
    height: 102.4px;
    position: absolute;
    top: -45px;
    right: -62px;
  }
  .BView4 {
    width: 128px;
    height: 90.05px;
    position: absolute;
    bottom: -45px;
    left: -63px;
  }
}
@media screen and (max-width: 875px) {
  .InputDevContainer {
    width: 150px;
    justify-content: flex-start;
  }
  .custom-MessageInput {
    height: 40px;
    border-radius: 25px;
    width: 80%;
    font-size: 10px;
  }
  .custom-input {
    height: 5px;
    width: 80%;
    border-radius: 15px;
  }
  .send-button {
    width: 60px;
    height: 23px;
    font-size: 10px;
    padding: 0;
  }
  .buttonContainer {
    width: 100%;
  }
  .ScoialIcon {
    height: 25px;
    margin-right: 15px;
  }
  .TextStyle {
    color: #fff;
    padding-left: 15px;
    margin-bottom: 10px;
    font-size: 12px;
  }
  .BView1 {
    width: 50px;
    height: 40px;
    position: absolute;
    top: -25px;
    left: -25px;
  }
  .BView2 {
    width: 60px;
    height: 67px;
    position: absolute;
    bottom: -27px;
    right: -25px;
  }
  .BView3 {
    width: 60px;
    height: 50px;
    position: absolute;
    top: -27px;
    right: -31px;
  }
  .BView4 {
    width: 65px;
    height: 45px;
    position: absolute;
    bottom: -23px;
    left: -30px;
  }
}
@media (max-width: 820px) {
  .ContactStyle {
    font-size: 34px;
  }
}
@media screen and (max-width: 502px) {
  .ContactStyle {
    font-size: 20px;
  }
}

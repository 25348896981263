/* -------------------------- NavBar     ----------------------------------------- */
.TextNavStyle {
    color: #fff;
    font-size: 30px;
    padding-left: 25px;
    text-decoration: none;
    cursor: pointer;
  }
.TextNavStyle:hover {
    color: #ff4e00;
    text-decoration: underline;
  }

/* -------------------------- Hero       ----------------------------------------- */
.Hero_Name_Style {
  font-size: 80px;
  color: rgb(255, 255, 255);
}
.Hero_Occupation_Style {
  font-size: 45px;
  color: rgb(255, 255, 255);
}
/* -------------------------- Unified    ----------------------------------------- */
.TitleStyle {
    color: white;
    font-family: "Dancing Script";
    font-size: 50px;
    padding: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    word-wrap: break-word;
    padding-left: 5vw;
}
.mainTitle {
    color: white;
    font-size: 26px;
    word-wrap: break-word;
  }
.SubmainTitle {
    color: white;
    font-size: 20px;
    padding: 5px 0 0 15px;
    word-wrap: break-word;
  }
.SubmainDescription {
    color: white;
    font-size: 18px;
    text-align: justify;
    margin-left: 15px;
    margin-bottom: 10px;
  }
.TechStyle {
    height: 20px;
    padding: 17px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0 0 10px rgb(255, 255, 255, 0.75);
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    height: auto;

  }
.Project_TechStyle_Text_Style {
    height: 20px;
    padding: 17px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0 0 10px rgb(255, 255, 255, 0.75);
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    color: white;
    font-size: 15px;
    height: auto;
  }  
/* -------------------------- Responsive ----------------------------------------- */
@media (max-width: 1280px) {
  .Hero_Name_Style {
    font-size: 60px;
  }
  .Hero_Occupation_Style {
    font-size: 40px;
  }
}
@media (max-width: 1205px) {
  .Hero_Name_Style {
    font-size: 50px;
  }
  .TextNavStyle{
    font-size: 24px;
  }
  .SubmainDescription {
    font-size: 16px;
  }
  .mainTitle {
    font-size: 22px;
  }
}
@media (max-width: 1024px) {
  .TextNavStyle{
    font-size: 22px;
  }
  .Hero_Name_Style {
    font-size: 50px;
  }
  .Hero_Occupation_Style {
    font-size: 40px;
  }
}
@media (max-width: 835px) {
  .TextNavStyle{
    font-size: 16px;
    padding-left: 10px;
  } 
  .Hero_Name_Style {
    font-size: 40px;
  }
  .Hero_Occupation_Style {
    font-size: 30px;
  }
}
@media (max-width: 820px) {
    .TitleStyle { 
        font-size: 44px;
    }
    .TitleExperience {
        font-size: 44px;
      }
    .mainTitle {
        font-size: 20px;
    }
    .SubmainTitle {
        font-size: 14px;
        text-align: justify;
    }
    .SubmainDescription {
        font-size: 14px;
    }
    .TechStyle {
        height: 15px;
        padding: 10px;
        font-size: 14px;
    }
    .Project_TechStyle_Text_Style {
        height: 15px;
        margin-bottom: 10px;
        font-size: 12px;
        padding: 10px 5px 10px 5px;
    }
    .Hero_Name_Style {
      font-size: 30px;
    }
    .Hero_Occupation_Style {
      font-size: 30px;
    }
}
@media (max-width: 502px) {
    .TitleStyle { 
        font-size: 34px;
    }
    .TitleExperience {
        font-size: 34px;
    }
    .mainTitle {
        font-size: 20px;
    }
    .SubmainTitle {
        font-size: 14px;
        text-align: justify;
    }
    .SubmainDescription {
        font-size: 14px;
    }
    .TechStyle {
        height: 15px;
        padding: 10px;
        font-size: 14px;
    }
    .Project_TechStyle_Text_Style {
        height: 15px;
        margin-bottom: 10px;
        font-size: 12px;
        padding: 10px 5px 10px 5px;
    }
    .Hero_Name_Style {
      font-size: 26px;
    }
    .Hero_Occupation_Style {
      font-size: 20px;
    }
}
.ContainerView {
  margin-right: 5vh;
  margin-left: 5vh;
  /* margin-top: 2vh; */
  display: flex;
  flex-direction: column;
  row-gap: 2vh;
  /* background-color: brown; */
  /* height: 100vh; */
  /* width: 100vw; */
}


.navbarContainer {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 21;
  transition: transform 0.3s ease;
} 
.navbar {
  width: 100%;
  height: 30px;
  padding: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* border-radius: 15px; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
  backdrop-filter: blur(5px);
}
.LogoIcon {
  width: 60px;
  height: 47px;
  margin-left: 50px;
  margin-right: 50px;
  cursor: pointer;
}
.LogoIcon:hover {
  cursor: pointer;
}

.NavIcontext {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-right: 50px;
}

/* NavBar for Phone  */
.hamburger_Logo_Icon_Syle {
  width: 30px;
  height: 25px;
  cursor: pointer;
}
.hamburger_Logo_Icon_Syle:hover {
  cursor: pointer;
}
.Main_hamburger_Nav_Style{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.hamburger_Nav_Style {
  width: 100vw;
  padding: 17px;
  padding-left: 15%;
  padding-right: 15%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.8);
}
.Hamburger_Nav_Icon_text_Style {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
/* Media query for window width under 621px */
@media screen and (max-width: 1732px) {
  .TextNavStyle {
    font-size: 25px;
  }
  .LogoIcon {
    width: 50px;
    height: 40px;
  }
}
@media screen and (max-width: 1534px) {
  .TextNavStyle {
    font-size: 18px;
  }
  .LogoIcon {
    width: 50px;
    height: 40px;
  }
}
@media (max-width: 834px) { 
  .NavIcontext{
    margin-right: 25px;
  }
}
@media screen and (max-width: 835px) {
  .LogoIcon {
    width: 40px;
    height: 30px;
  } 
  .navbar { 
    height: 15px;
  }
} 
/* Set background image */
body {
  margin: 0;
  padding: 0;
  background: url("./Assets/BG/14.jpeg?v=2") center/cover fixed no-repeat;
  /* background: url("./Assets/BG/p1.jpeg?v=2") center/cover fixed no-repeat;  */

  /* background: url("./Assets/BG/9.jpegs") center/cover fixed no-repeat; */
  /* background: url("./Assets/BG/13.jpeg") center/cover fixed no-repeat; */
}
@media (max-width: 687px) {
  body {
    background: url("./Assets/BG/p1.jpeg?v=2") center/cover fixed no-repeat; 
  }
} 
.overlay {
  width: 100vw;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.3
  );
  backdrop-filter: blur(2px); 
}
.messageRights {
  color: white;
  font-size: large;
  align-items: center;
  align-content: center;
  text-align: center;
}

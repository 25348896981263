/* Container View */
.ContainerViewCert {
  /* padding-left: 5vw; */
  /* padding-top: 10vh; */
}
.VerticalViewCert {
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 20px;
}
.VerticalViewCertReversed {
  height: 200px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  column-gap: 20px;
}

.CertCard {
  /* width: 225px; */
  /* height: 210px; */
  padding: 17px;
  width: 60%;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
  border-radius: 40px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.75),
    /* Red color with 50% opacity */ rgba(0, 0, 0, 0),
    /* Green color with 30% opacity */ rgba(0, 0, 0, 0.75)
      /* Blue color with 70% opacity */
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
  align-items: flex-start;
  backdrop-filter: blur(20px);
}
.custom-Exp-hr {
  width: 100%;
  border: none;
  height: 1px;
  margin-bottom: 15px;
  background: linear-gradient(
    to right,
    rgba(96, 46, 166, 0.5),
    rgba(201, 119, 214, 1),
    rgba(96, 46, 166, 0)
  );
}
.TitleContainerCert{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}
.CertView {
  width: 160px;
  height: 160px;
}
.certcontainer {
  display: flex;
  flex-direction: column;
  row-gap: 60px;
}
.LinkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1250px) {
  .mainCertTitle {
    font-size: 22px;
  }
  .SubmainCertTitle {
    font-size: 14px;
  }
}
@media screen and (max-width: 984px) {
  .mainCertTitle {
    font-size: 18px;
  }
  .SubmainCertTitle {
    font-size: 12px;
  }
  .CertCard {
    /* height: ""; */
  }
} 
@media screen and (max-width: 650px) {
  .VerticalViewCert,
  .VerticalViewCertReversed {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    gap: 20px;
  }
  .mainCertTitle {
    font-size: 20px;
  }
  .SubmainCertTitle {
    font-size: 16px;
    text-align: justify;
  }
  .CertStyle {
    font-size: 34px;
  }
  .CertCard {
    width: 80vw;
    height: auto;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 502px) {
  .CertStyle {
    font-size: 20px;
  }
}

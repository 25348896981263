.ExperienceView {
  /* padding-left: 5vw; */
}
.WorkContainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.CompanyContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.CompanyContainerReversed {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  align-items: center;
}
.CompanyLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.CompanyLogo {
  width: 250px;
  height: 250px;
  border-radius: 25px;
}
.DescriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 70vw;
  color: white;
  /* the containe view section  */
  padding: 30px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
  border-radius: 45px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
}

.TechContainer {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.custom-Exp-hr {
  width: 100%;
  border: none;
  height: 1px;
  margin-bottom: 15px;
  background: linear-gradient(
    to right,
    rgba(96, 46, 166, 0.5),
    rgba(201, 119, 214, 1),
    rgba(96, 46, 166, 0)
  );
}

@media (max-width: 820px) {
  .CompanyContainer,
  .CompanyContainerReversed {
    align-items: flex-start;
  }
  .CompanyLogo {
    width: 150px;
    height: auto;
  }
  .TechContainer {
    column-gap: 10px;
    justify-content: space-around;
  }
}
@media (max-width: 502px) {
  .CompanyContainer,
  .CompanyContainerReversed {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .CompanyLogo {
    width: 150px;
    height: auto;
  }
  .TechContainer {
    column-gap: 15px;
    justify-content: space-around;
  }
}

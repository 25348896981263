/* Container View */
.FigmaContainerStyle {
  /* padding-left: 5vw; */
} 
/* image card container style section  */
.AppContainerStyle {
  display: flex;
  justify-content: center;
}
.ImageScrollingStyle {
  height: 500px;
}
/* Responsivness */
@media screen and (max-width: 1024px) {
  .ImageScrollingStyle {
    height: 400px;
    width: 550px;
  }
}
@media screen and (max-width: 690px) {
  .ImageScrollingStyle {
    height: 350px;
    width: 550px;
  }
}
@media screen and (max-width: 690px) {
  .ImageScrollingStyle {
    height: 250px;
    width: 350px;
  }
} 
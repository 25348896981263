.ProjectView{
  /* padding-left: 5vw; */
}
.Project_Container_Style {
  display: flex;
  row-gap: 20px;
  flex-direction: column; 
}
.Project_Form_Style {
  display: flex;
  justify-content: space-between;
  align-items: center;

} 
/* Apply flex-direction: row; for odd elements */
.Project_Form_Style:nth-child(even) {
  flex-direction: row-reverse;
  justify-content: flex-start;
}
/* Apply flex-direction: row-reverse; for even elements */
.Project_Form_Style:nth-child(odd){
  flex-direction: row;
  justify-content: flex-start;
}
.ProjectDetailStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
}
/* ---------------------------------- Info Section --------------------- */
.Project_Info_Style {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  width: 60vw;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
  border-radius: 45px;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
}
.Project_Title_Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Project_custom_hr {
  border: none;
  height: 3px;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(96, 46, 166, 0.5),
    rgba(201, 119, 214, 1),
    rgba(96, 46, 166, 0)
  );
}
.Project_Type_Style {
  color: rgb(255, 255, 255);
  font-size: 16px;
  margin-left: 15px;
  margin-top: 10px;
}
.Project_TechContainer_Style {
  margin: 15px 0 15px 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}
/* ---------------------------------- Mobile Icon Section --------------------- */
.Project_GitHubH_Icon {
  width: 84px;
  height: 84px;
}
.Mobile_Image_Style {
  width: 150px;
  height: 300px;
}
.Website_Image_Style {
  width: 390px;
  height: 300px;
}
/* ---------------------------------- view website Button sections --------------------- */
.Button_Container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Check_website_button {
  width: 216px;
  height: 46px;
  align-items: center;
  display: flex;
  margin-top: 10px;
  box-shadow: 0 0 5px rgb(138, 137, 137);
  font-size: 20px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 15px;
  border: 1px solid white;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}
.Check_website_button:hover {
  background-color: rgb(49, 58, 91);
}

/* ---------------------------------- Responsive sections --------------------- */

@media screen and (max-width: 968px) {
  .Project_Form_Style:nth-child(even),
  .Project_Form_Style:nth-child(odd) {
    flex-direction: column;
    align-items: center;
  } 
  .Project_Info_Style {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    width: 100%;
  }
}
@media (max-width: 820px) {
  .Website_Image_Style {
    width: 200px;
    height: 150px;
  }
  .Project_GitHubH_Icon {
    width: 64px;
    height: 64px;
  }
  .Project_Title_Style {
    font-size: 20px;
  }
  .info_SubText_Style {
    font-size: 20px;
  }
  .Project_TechContainer_Style {
    column-gap: 12px;
  }
  .Check_website_button {
    width: 180px;
    height: 40px;
    font-size: 15px;
  }
  .ProjectDetailStyle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
  }
  .Title_Project_Style {
    font-size: 44px;
  }
} 
@media screen and (max-width: 502px) {
  .Website_Image_Style {
    width: 200px;
    height: 150px;
  }
  .Project_GitHubH_Icon {
    width: 64px;
    height: 64px;
  }
  .Project_Title_Style {
    font-size: 20px;
  }
  .info_SubText_Style {
    font-size: 20px;
  }
  .Project_TechContainer_Style {
    column-gap: 12px;
  }
  .Check_website_button {
    width: 180px;
    height: 40px;
    font-size: 15px;
  }
  .ProjectDetailStyle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
  }
  .Title_Project_Style {
    font-size: 34px;
  }
}

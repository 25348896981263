  .Hero_Container_Style{
    /* box-shadow: 0 1px 5px rgb(255, 0, 0); */
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: row;
  }
  /* Right Side  */
  .Right_Container_Style {
    /* box-shadow: 0 2px 5px rgb(255, 255, 255); */
    width: 40%;
    height: 100%;
  }
  .Top_Right_Child_Container_Style {
    /* box-shadow: 0 1px 5px rgb(0, 255, 30); */
    width: 100%;
    height: 80%;
  }
  .Bottom_Right_Child_Container_Style {
    /* box-shadow: 0 1px 5px rgb(252, 0, 193); */
    width: 100%;
    height: 20%;
  }
  /* Left Side  */
  .Left_Container_Style {
    /* box-shadow: 0 2px 5px rgba(0, 195, 255, 0.816); */
    width: 60%;
    height: 100%;
  }
  .Top_Left_Child_Container_Style {
    /* box-shadow: 0 1px 5px rgb(115, 96, 201); */
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .Bottom_Left_Child_Container_Style {
    /* box-shadow: 0 1px 5px rgb(112, 63, 100); */
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
  }

  /* Animation Style side  */
  .Animated_Container_Style {
    width: 100%;
    height: 100%;
    /* box-shadow: 0 2px 5px rgb(255, 255, 255); */
  }
  .Animated_Small_Container_Style {
    width: 40%;
    height: 100%;
    /* box-shadow: 0 2px 5px rgb(255, 255, 255); */
  }

  /* Social Incon Style  */
  .Social_container_Style{
    display: flex;
    column-gap: 20px;
  }
  .Scoial_Icon_Style {
    height: auto;
    width: 52px;
    margin-right: 30px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 10px 1px rgba(0, 195, 255, 0.816), 0 0 10px white;     
  }
  /* Hover effect */
  .Social_Icon_Style:hover {
    /* box-shadow: 0 0 10px 1px rgba(255, 140, 0, 0.816), 0 0 10px white;  */
    cursor: pointer;
  }
  /* Info Container  */
  .Info_Container_Style{
    /* box-shadow: 0 0 10px 1px rgba(255, 140, 0, 0.816), 0 0 10px white;  */
    width: 75%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .Animated_Social_Container_Style {
    /* box-shadow: 0 0 10px 1px rgba(255, 140, 0, 0.816), 0 0 10px white;  */
    width: 75%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
/* -------------------------- Responsive ----------------------------------------- */
@media (max-width: 1280px) {
  .Scoial_Icon_Style{
    height: 42px;
    width: 42px;
  }
  .Info_Container_Style{
    height: 20%;
  }
}
@media (max-width: 834px) {
  .Hero_Container_Style{
    margin-top: 5vh;
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    /* height: 100%; */
  }
  .Left_Container_Style,
  .Right_Container_Style {
    width: 100%;
  }
  .Top_Right_Child_Container_Style,
  .Top_Left_Child_Container_Style {
    height: auto;
  }
  .Scoial_Icon_Style{
    height: 32px;
    width: 32px;
    margin-right: 0;
    height: auto;
  }
  .Info_Container_Style{
    height: 10%;
  }
}
@media (max-width: 820px) { 
  .Animated_Social_Container_Style{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    column-gap: 15px;
  }
  .Scoial_Icon_Style{
    height: 25px;
    width: 25px;
  }
  .Animated_Small_Container_Style{
    width: 60%;
  }
}